import { BoxGeometry, CylinderGeometry, Mesh, PlaneGeometry } from "three"
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js"

export function CreatePlane(SetObject, ThrowException, key, props) {
    let {width, height, material} = props
    let geometry = new PlaneGeometry(width, height)
    let object = new Mesh(geometry, material)
    object.receiveShadow = true
    object.castShadow = true
    SetObject({
        type: "plane",
        key: key,
        model: object
    })
}

export function CreateBox(SetObject, ThrowException, key, props) {
    let {width, height, depth, material} = props
    let geometry = new BoxGeometry(width, height, depth)
    let object = new Mesh(geometry, material)
    object.receiveShadow = true
    object.castShadow = true
    SetObject({
        type: "box",
        key: key,
        model: object
    })
}

export function CreateCylinder(SetObject, ThrowException, key, props) {
    let {radius, height, material} = props
    let geometry = new CylinderGeometry(radius, radius, height, Math.floor(2 * Math.PI * radius))
    let object = new Mesh(geometry, material)
    object.receiveShadow = true
    object.castShadow = true
    SetObject({
        type: "cylinder",
        key: key,
        model: object
    })
}

export function CreateText(SetObject, ThrowException, key, props) {
    let {text, params, material} = props
    let geometry = new TextGeometry(text, params)
    var object = new Mesh(geometry, material)
    object.receiveShadow = true
    object.castShadow = true
    SetObject({
        type: "text",
        key: key,
        model: object
    })
}