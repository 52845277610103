import { Scene, PerspectiveCamera, WebGLRenderer, AmbientLight, HemisphereLight, Color, PointLight, SpotLight } from "three"
import { cameraProps } from "../objects"
import { InteractionManager } from "three.interactive"
import { HandleKeyDown, HandleKeyUp, HandleMouseDown, HandleOrient, OnWindowResize } from "./handlers"
import { PointerLockControls } from "three/examples/jsm/controls/PointerLockControls.js"
import { MovementProps } from "../objects"

export function InitEnv(SetEnv, ThrowException, location) {
    let scene = new Scene()
    window.scene = scene
    let camera = InitCamera(location)
    let renderer = InitRenderer()
    let interaction = new InteractionManager(
        renderer,
        camera,
        renderer.domElement
    )
    let lightings = InitLighting(location)
    lightings.forEach((element) => {
        scene.add(element)
    })
    let movement = MovementProps
    let prevTime = performance.now()
    document.body.appendChild(renderer.domElement)
    window.addEventListener("resize", () => OnWindowResize(camera, renderer), false)
    if (window.innerWidth > window.innerHeight) {
        document.addEventListener("keydown", (event) => HandleKeyDown(event, movement))
        document.addEventListener("keyup", (event) => HandleKeyUp(event, movement))
    }
    else {
        window.addEventListener("deviceorientation", (event) => HandleOrient (event, movement), true)
        document.addEventListener("mousedown", (event) => HandleMouseDown(event, movement))
    }
    let control = new PointerLockControls(camera, renderer.domElement)
    scene.add(control.getObject())
    SetEnv({
        scene,
        camera,
        renderer,
        interaction,
        prevTime,
        movement,
        control
    })
}

function InitLighting(location) {
    let lightings = []
    if (location == "entrance") {
        // let hemiLight = new HemisphereLight(0xffffff, 0x6592d5, 0.6)
        // hemiLight.position.set( -19, 5, 22 )
        // hemiLight.castShadow = true
        // lightings.push(hemiLight)
        let ambientLight = new AmbientLight(0xffffff, 0.9)
        lightings.push(ambientLight)
        let pointLight = new PointLight(0xffffff, 0.8)
        pointLight.position.set(15, 3, -10)
        pointLight.scale.x = 20
        pointLight.scale.y = 20
        pointLight.scale.z = 20
        pointLight.castShadow = true
        lightings.push(pointLight)
        let pointLight1 = new PointLight(0xffffff, 0.35)
        pointLight1.position.set(0, 3, 3)
        pointLight1.castShadow = true
        lightings.push(pointLight1)
        let spotLight = new SpotLight(0xffffff, 0.8, 25.0, Math.PI/4.0, 0.5, 1.0)
        spotLight.position.set(15, 5, -10)
        spotLight.target.position.set(0, 0, 0)
        spotLight.castShadow = true
        lightings.push(spotLight)
    }
    if (location == "ceo") {
        // let hemiLight = new HemisphereLight(0xffffff, 0xffffff, 0.4)
        // hemiLight.position.set( 0, -2, -20 )
        // hemiLight.castShadow = true
        // lightings.push(hemiLight)
        let ambientLight = new AmbientLight(0xffffff, 1)
        lightings.push(ambientLight)
        let pointLight = new PointLight(0x808080, 0.2)
        pointLight.position.set(-15, 4, 0)
        pointLight.castShadow = true
        lightings.push(pointLight)
        let pointLight2 = new PointLight(0x808080, 0.2)
        pointLight2.position.set(20, 4, 0)
        pointLight2.castShadow = true
        lightings.push(pointLight2)
        let pointLight3 = new PointLight(0x808080, 0.2)
        pointLight3.position.set(20, 4, -30)
        pointLight3.castShadow = true
        lightings.push(pointLight3)
        let pointLight4 = new PointLight(0x808080, 0.2)
        pointLight4.position.set(-15, 4, -30)
        pointLight4.castShadow = true
        lightings.push(pointLight4)
        // let pointLight5 = new PointLight(0xffffff, 0.4)
        // pointLight5.position.set(0, 12, -20)
        // pointLight5.castShadow = true
        // lightings.push(pointLight5)
    }
    if (location == "coo") {
        let ambientLight = new AmbientLight(0xffffff, 1)
        lightings.push(ambientLight)
        let pointLight = new PointLight(0x808080, 0.2)
        pointLight.position.set(-15, 4, 0)
        pointLight.castShadow = true
        lightings.push(pointLight)
        let pointLight2 = new PointLight(0x808080, 0.2)
        pointLight2.position.set(20, 4, 0)
        pointLight2.castShadow = true
        lightings.push(pointLight2)
        let pointLight3 = new PointLight(0x808080, 0.2)
        pointLight3.position.set(20, 4, -30)
        pointLight3.castShadow = true
        lightings.push(pointLight3)
        let pointLight4 = new PointLight(0x808080, 0.2)
        pointLight4.position.set(-15, 4, -30)
        pointLight4.castShadow = true
        lightings.push(pointLight4)
        // let pointLight5 = new PointLight(0xffffff, 0.4)
        // pointLight5.position.set(0, 12, -25)
        // pointLight5.castShadow = true
        // lightings.push(pointLight5)
    }
    if (location == "cto") {
        let ambientLight = new AmbientLight(0xffffff, 1)
        lightings.push(ambientLight)
        let pointLight = new PointLight(0x808080, 0.2)
        pointLight.position.set(-15, 4, 0)
        pointLight.castShadow = true
        lightings.push(pointLight)
        let pointLight2 = new PointLight(0x808080, 0.2)
        pointLight2.position.set(20, 4, 0)
        pointLight2.castShadow = true
        lightings.push(pointLight2)
        let pointLight3 = new PointLight(0x808080, 0.2)
        pointLight3.position.set(20, 4, -30)
        pointLight3.castShadow = true
        lightings.push(pointLight3)
        let pointLight4 = new PointLight(0x808080, 0.2)
        pointLight4.position.set(-15, 4, -30)
        pointLight4.castShadow = true
        lightings.push(pointLight4)
        // let pointLight5 = new PointLight(0xffffff, 0.4)
        // pointLight5.position.set(0, 12, -25)
        // pointLight5.castShadow = true
        // lightings.push(pointLight5)
    }
    if (location == "cfo") {
        let ambientLight = new AmbientLight(0xffffff, 1)
        lightings.push(ambientLight)
        let pointLight = new PointLight(0x808080, 0.2)
        pointLight.position.set(-15, 4, 0)
        pointLight.castShadow = true
        lightings.push(pointLight)
        let pointLight2 = new PointLight(0x808080, 0.2)
        pointLight2.position.set(20, 4, 0)
        pointLight2.castShadow = true
        lightings.push(pointLight2)
        let pointLight3 = new PointLight(0x808080, 0.2)
        pointLight3.position.set(20, 4, -30)
        pointLight3.castShadow = true
        lightings.push(pointLight3)
        let pointLight4 = new PointLight(0x808080, 0.2)
        pointLight4.position.set(-15, 4, -30)
        pointLight4.castShadow = true
        lightings.push(pointLight4)
        // let pointLight5 = new PointLight(0xffffff, 0.4)
        // pointLight5.position.set(0, 12, -25)
        // pointLight5.castShadow = true
        // lightings.push(pointLight5)
    }
    if (location == "directorcrm") {
        let ambientLight = new AmbientLight(0xffffff, 1)
        lightings.push(ambientLight)
        let pointLight = new PointLight(0x808080, 0.4)
        pointLight.position.set(0, 0, 0)
        pointLight.castShadow = true
        lightings.push(pointLight)
    }
    if (location == "directorctd") {
        let ambientLight = new AmbientLight(0xffffff, 1)
        lightings.push(ambientLight)
        let pointLight = new PointLight(0x808080, 0.2)
        pointLight.position.set(-15, 4, 0)
        pointLight.castShadow = true
        lightings.push(pointLight)
        let pointLight2 = new PointLight(0x808080, 0.2)
        pointLight2.position.set(20, 4, 0)
        pointLight2.castShadow = true
        lightings.push(pointLight2)
        let pointLight3 = new PointLight(0x808080, 0.2)
        pointLight3.position.set(20, 4, -30)
        pointLight3.castShadow = true
        lightings.push(pointLight3)
        let pointLight4 = new PointLight(0x808080, 0.2)
        pointLight4.position.set(-15, 4, -30)
        pointLight4.castShadow = true
        lightings.push(pointLight4)
        // let pointLight5 = new PointLight(0xffffff, 0.4)
        // pointLight5.position.set(0, 12, -25)
        // pointLight5.castShadow = true
        // lightings.push(pointLight5)
    }
    if (location == "firstday") {
        // let hemiLight = new HemisphereLight(0xffffff, 0xffffff, 1.0)
        // hemiLight.position.set( -19, 5, 22 )
        // hemiLight.castShadow = true
        // lightings.push(hemiLight)
        let ambientLight = new AmbientLight(0xffffff, 1)
        lightings.push(ambientLight)
        let pointLight = new PointLight(0x808080, 0.1)
        pointLight.position.set(-5, 5, -6)
        pointLight.castShadow = true
        lightings.push(pointLight)
        let pointLight2 = new PointLight(0x808080, 0.1)
        pointLight2.position.set(0, 5, -6)
        pointLight2.castShadow = true
        lightings.push(pointLight2)
        let pointLight3 = new PointLight(0x808080, 0.1)
        pointLight3.position.set(0, 5, 0)
        pointLight3.castShadow = true
        lightings.push(pointLight3)
        let pointLight4 = new PointLight(0x808080, 0.1)
        pointLight4.position.set(-5, 5, 0)
        pointLight4.castShadow = true
        lightings.push(pointLight4)
        let pointLight5 = new PointLight(0xffffff, 0.1)
        pointLight5.position.set(-2, 5, -1)
        pointLight5.castShadow = true
        lightings.push(pointLight5)
    }
    if (location == "receptionist") {
        let hemiLight = new HemisphereLight(0xffffff, 0xffffff, 1.0)
        hemiLight.position.set( -19, 5, 22 )
        hemiLight.castShadow = true
        lightings.push(hemiLight)
        let ambientLight = new AmbientLight(0xDCF1FF, 0.2)
        lightings.push(ambientLight)
        let pointLight = new PointLight(0xffffff, 0.3)
        pointLight.position.set(0, 0, 0)
        pointLight.castShadow = true
        lightings.push(pointLight)
    }
    if (location == "sectionhead") {
        // let hemiLight = new HemisphereLight(0xffffff, 0xffffff, 1.0)
        // hemiLight.position.set( -19, 5, 22 )
        // hemiLight.castShadow = true
        // lightings.push(hemiLight)
        let ambientLight = new AmbientLight(0xffffff, 1)
        lightings.push(ambientLight)
        let pointLight = new PointLight(0x808080, 0.2)
        pointLight.position.set(-15, 4, 0)
        pointLight.castShadow = true
        lightings.push(pointLight)
        let pointLight2 = new PointLight(0x808080, 0.2)
        pointLight2.position.set(20, 4, 0)
        pointLight2.castShadow = true
        lightings.push(pointLight2)
        let pointLight3 = new PointLight(0x808080, 0.2)
        pointLight3.position.set(20, 4, -30)
        pointLight3.castShadow = true
        lightings.push(pointLight3)
        let pointLight4 = new PointLight(0x808080, 0.2)
        pointLight4.position.set(-15, 4, -30)
        pointLight4.castShadow = true
        lightings.push(pointLight4)
        // let pointLight5 = new PointLight(0xffffff, 0.4)
        // pointLight5.position.set(0, 12, -25)
        // pointLight5.castShadow = true
        // lightings.push(pointLight5)
    }

    return lightings
}

function InitCamera(location) {
    let camera = new PerspectiveCamera(
        60,
        window.innerWidth / window.innerHeight,
        1,
        1000
    )
    let {position, rotate} = cameraProps[location]
    camera.position.x = position.x
    camera.position.y = position.y
    camera.position.z = position.z
    camera.rotateX(rotate.x)
    camera.rotateY(rotate.y)
    camera.rotateZ(rotate.z)
    return camera
}

function InitRenderer() {
    let renderer = new WebGLRenderer({
        antialias: true,
        alpha: true
    })
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setClearColor(new Color(0x061D37), 0.8)

    return renderer
}