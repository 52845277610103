import entranceroom from "../src/assets/model/entrancedoor2.gltf"
import ceoroom from "../src/assets/model/office.gltf"
import cforoom from "../src/assets/model/office.gltf"
import cooroom from "../src/assets/model/office.gltf"
import ctoroom from "../src/assets/model/office.gltf"
import directorcrmroom from "../src/assets/model/sains contact centre room.gltf"
import directorctdroom from "../src/assets/model/office.gltf"
import firstdayroom from "../src/assets/model/office working rooms.gltf"
import receptionistroom from "../src/assets/model/Receptionist.gltf"
import sectionheadroom from "../src/assets/model/office.gltf"

import entranceceo from "../src/assets/model/ceochat5.gltf"
// ceochat1,ceochat2,ceochat3,ceochat4,ceochat5
import entrancecto from "../src/assets/model/ctochat.gltf"
import entranceshcm from "../src/assets/model/sectionhchat1.gltf"
// sectionhchat1,sectionhchat2,sectionhchat3,sectionhchat4,sectionhchat5
import entrancecoo from "../src/assets/model/coochat2.gltf"
// coochat1,coochat2,coochat3,coochat4
import entrancectdd from "../src/assets/model/directorctdchat1.gltf"
// directorctdchat1,directorctdchat2,directorctdchat3
import entrancecfo from "../src/assets/model/cfochat2.gltf"
// cfochat1,cfochat2,cfochat3
import entrancecrmd from "../src/assets/model//directorcrmchat1-2.gltf"
// directorcrmchat1-1,directorcrmchat1-2,directorcrmchat1-3

import arrow from "../src/assets/model/Arrowgreen.gltf"
import arrow1 from "../src/assets/model/arrow.gltf" //blue arrow

import lady1 from "../src/assets/model/1.gltf"
import lady2 from "../src/assets/model/2.gltf"
import lady3 from "../src/assets/model/3.gltf"

import boss from "../src/assets/model/ceochat1.gltf"
import boss2 from "../src/assets/model/ceochat2.gltf"
import boss3 from "../src/assets/model/ceochat3.gltf"
import boss4 from "../src/assets/model/ceochat4.gltf"
import boss5 from "../src/assets/model/ceochat5.gltf"

import coo1 from "../src/assets/model/coochat1.gltf"
import coo2 from "../src/assets/model/coochat2.gltf"
import coo3 from "../src/assets/model/coochat3.gltf"
import coo4 from "../src/assets/model/coochat4.gltf"

import cto1 from "../src/assets/model/ctochat.gltf"

import cfo1 from "../src/assets/model/cfochat1.gltf"
import cfo2 from "../src/assets/model/cfochat2.gltf"
import cfo3 from "../src/assets/model/cfochat3.gltf"

import directorcrm1 from "../src/assets/model/directorcrmchat1.gltf"
import directorcrm2 from "../src/assets/model/directorcrmchat2.gltf"
import directorcrm3 from "../src/assets/model/directorcrmchat3.gltf"
import directorcrm4 from "../src/assets/model/directorcrmchat4.gltf"
import directorcrm5 from "../src/assets/model/directorcrmchat5.gltf"
import ambd from "../src/assets/img/ambd.png"
import smi from "../src/assets/img/smi.png"
import slm from "../src/assets/img/slm.png"
import ccs from "../src/assets/img/ccs.png"

import directorctd1 from "../src/assets/model/directorctdchat1.gltf"
import directorctd2 from "../src/assets/model/directorctdchat2.gltf"
import directorctd3 from "../src/assets/model/directorctdchat3.gltf"
import integrity from "../src/assets/img/integrity.png"
import passion from "../src/assets/img/passion.png"
import innovation from "../src/assets/img/innovative.png"
import respect from "../src/assets/img/respect.png"
import excellence from "../src/assets/img/excellence.png"

import sectionh1 from "../src/assets/model/sectionhchat1.gltf"
import sectionh2 from "../src/assets/model/sectionhchat2.gltf"
import sectionh3 from "../src/assets/model/sectionhchat3.gltf"
import sectionh4 from "../src/assets/model/sectionhchat4.gltf"
import sectionh5 from "../src/assets/model/sectionhchat5.gltf"
import iso9001 from "../src/assets/img/iso9001.png"
import iso27001 from "../src/assets/img/iso27001.png"
import kpi from "../src/assets/img/kpi.png"
import bcp from "../src/assets/img/bcp.png"

import firstday1 from "../src/assets/model/apelchat1.gltf"
import firstday2 from "../src/assets/model/apelchat2.gltf"

import { MeshBasicMaterial, Vector3 } from "three"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { iframeContent, imgContent } from "../src/helpers/modal"
import helvetiker from "../src/assets/fonts/helvetiker_bold.typeface.json"

const fontLoader = new FontLoader()
export const playerProps = {
    mass: 100.0,
    height: 4
}

export const cameraProps = {
    entrance: {
        position : {
            x: 0,
            y: playerProps.height,
            z: 25
        },
        rotate: {
            x: 0,
            y: -0.1 * Math.PI,
            z: 0
        },
    },
    ceo: {
        position : {
            x: 0,
            y: playerProps.height,
            z: -10
        },
        rotate: {
            x: 0,
            y: -0.05* Math.PI,
            z: 0
        }
    },
    cfo: {
        position : {
            x: 0,
            y: playerProps.height,
            z: -10
        },
        rotate: {
            x: 0,
            y: -0.05* Math.PI,
            z: 0
        }
    },
    coo: {
        position : {
            x: 0,
            y: playerProps.height,
            z: -10
        },
        rotate: {
            x: 0,
            y: -0.05* Math.PI,
            z: 0
        }
    },
    cto: {
        position : {
            x: 0,
            y: playerProps.height,
            z: -10
        },
        rotate: {
            x: 0,
            y: -0.05* Math.PI,
            z: 0
        }
    },
    directorcrm: {
        position : {
            x: 5,
            y: playerProps.height,
            z: -1
        },
        rotate: {
            x: 0,
            y: 0.2 * Math.PI,
            z: 0
        }
    },
    directorctd: {
        position : {
            x: 0,
            y: playerProps.height,
            z: -10
        },
        rotate: {
            x: 0,
            y: -0.05* Math.PI,
            z: 0
        }
    },
    firstday: {
        position : {
            x: 0,
            y: playerProps.height,
            z: 0
        },
        rotate: {
            x: 0,
            y: 0.25* Math.PI,
            z: 0
        },
    },
    receptionist: {
        position : {
            x: 0,
            y: playerProps.height,
            z: 0
        },
        rotate: {
            x: 0,
            y: -0.1* Math.PI,
            z: 0
        }
    },
    sectionhead: {
        position : {
            x: 0,
            y: playerProps.height,
            z: -10
        },
        rotate: {
            x: 0,
            y: -0.05* Math.PI,
            z: 0
        },
    },
}

export const ControlProps = {
	velocity: new Vector3,
	direction: new Vector3
}

export const MovementProps = {
	enabled: true,
	moveForward: false,
	moveBackward: false,
	moveLeft: false,
	moveRight: false,
	turnAngle: 0,
	prevAngle: 0,
	jumping: {
		value: false,
		allowed: false
	},
    speed: 1
}

export let models = {
    entrance: {
        entranceroom,
        arrow,
        entranceceo,
        entrancecoo,
        entrancecfo,
        entrancecto,
        entranceshcm,
        entrancectdd,
        entrancecrmd,
    },
    ceo: {
        ceoroom,
        arrow,
        boss,
        boss2,
        boss3,
        boss4,
        boss5,
        arrow1,
        // bulb,
    },
    cfo: {
        cforoom,
        arrow,
        cfo1,
        cfo2,
        cfo3,
        arrow1,
    },
    coo: {
        cooroom,
        arrow,
        coo1,
        coo2,
        coo3,
        coo4,
        arrow1,
    },
    cto: {
        ctoroom,
        arrow,
        cto1,
        arrow1,
    },
    directorcrm: {
        directorcrmroom,
        arrow,
        directorcrm1,
        directorcrm2,
        directorcrm3,
        directorcrm4,
        directorcrm5,
        arrow1,
    },
    directorctd: {
        directorctdroom,
        arrow,
        directorctd1,
        directorctd2,
        directorctd3,
        arrow1,
    },
    firstday: {
        firstdayroom,
        firstday1,
        firstday2,
        arrow1,
    },
    receptionist: {
        receptionistroom,
        arrow,
        lady1,
        lady2,
        lady3,
        arrow1
    },
    sectionhead: {
        sectionheadroom,
        sectionh1,
        sectionh2,
        sectionh3,
        sectionh4,
        sectionh5,
        arrow,
        arrow1,
    },
}

export let sequentialModels = {
    ceo: [
        ['chat', 'boss'],
        ['chat2', 'boss5'],
        ['chat3', 'boss3'],
        ['chat4', 'boss2'],
        ['chat5', 'boss4'],
        ['chat6', 'boss5'],
    ],
    cfo:[
        ['chat', 'cfo1'],
        ['chat2', 'cfo2'],
        ['chat3', 'cfo3'],
        ['chat4', 'cfo2'],
        ['chat5', 'cfo3'],
    ],
    coo: [
        ['chat', 'coo1'],
        ['chat2', 'coo3'],
        ['chat3', 'coo4'],
        ['chat4', 'coo3'],
        ['chat5', 'coo2'],
    ],
    cto:[
        ['chat'],
        ['chat2'],
        ['chat3'],
        ['chat4'],
        ['chat5'],
    ],
    directorcrm: [
        ['directorcrm1'],
        ['directorcrm2'],
        ['directorcrm3'],
        ['directorcrm4'],
        ['directorcrm5'],
    ],
    directorctd: [
        ['chat', 'directorctd1'],
        ['chat2', 'directorctd3'],
        ['chat3', 'directorctd2'],
        ['chat4', 'directorctd3'],
    ],
    firstday: [
        ['firstday1'],
        ['firstday2'],
    ],
    receptionist: [
        ['lady1'],
        ['lady2'],
        ['lady3'],
    ],
    sectionhead: [
        ['chat', 'sectionh1'],
        ['chat2', 'sectionh4'],
        ['chat3', 'sectionh4'],
        ['chat5', 'sectionh2'],
    ]
}

/* texture data structure
item_name: {
    path: path,
    repeatX: value,
    repeatY: value
}
*/
export let textures = {
    entrance: {},
    ceo: {},
    cfo: {},
    coo: {},
    cto: {},
    directorcrm: {
        ambd_cylinder: {
            path: ambd,
            repeatX: 1,
            repeatY: 1
        },
        smi_cylinder: {
            path: smi,
            repeatX: 1,
            repeatY: 1
        },
        slm_cylinder: {
            path: slm,
            repeatX: 1,
            repeatY: 1
        },
        ccs_cylinder: {
            path: ccs,
            repeatX: 1,
            repeatY: 1
        },
    },
    directorctd: {
        integrity_cylinder: {
            path: integrity,
            repeatX: 1,
            repeatY: 1
        },
        passion_cylinder: {
            path: passion,
            repeatX: 1,
            repeatY: 1
        },
        innovation_cylinder: {
            path: innovation,
            repeatX: 1,
            repeatY: 1
        },
        respect_cylinder: {
            path: respect,
            repeatX: 1,
            repeatY: 1
        },
        excellence_cylinder: {
            path: excellence,
            repeatX: 1,
            repeatY: 1
        },
    },
    firstday: {},
    receptionist: {},
    sectionhead: {
        iso9001_cylinder: {
            path: iso9001,
            repeatX: 1,
            repeatY: 1
        },
        iso27001_cylinder: {
            path: iso27001,
            repeatX: 1,
            repeatY: 1
        },
        kpi_cylinder: {
            path: kpi,
            repeatX: 1,
            repeatY: 1
        },
        bcp_cylinder: {
            path: bcp,
            repeatX: 1,
            repeatY: 1
        },
    },
}

/*
item_name : {
    geometry: geometry type,
    text: value, *** for texts
    params: {paramaters}, *** for texts
    width: value, *** except cylinder
    height: value,
    depth: value, *** for boxes
    radius: value, *** for cylinders
    material: material,
}
*/
export let geometries = {
    entrance: {
        next: {
            geometry: "text",
            text: "Get started",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0x000000,
            })
        }
    },
    receptionist: {
        back: {
            geometry: "text",
            text: "Entrance",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0x000000,
            })
        },
        next: {
            geometry: "text",
            text: "CEO Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
    },
    ceo: {
        back: {
            geometry: "text",
            text: "Reception",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        next: {
            geometry: "text",
            text: "CTO Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        chat: {
            geometry: "text",
            text: "Greetings. Congratulation for passing the bar and \n" +
                  "coming onboard as part of the SAINS Community. I am \n" +
                  "Dr. Anderson Tiong, the Chief Executive Officer of \n" +
                  "SAINS. Our Company VISION is to become a world-class \n" +
                  "digital solutions and services provider here in SAINS.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat2: {
            geometry: "text",
            text: "What is a Vision? A vision is something that you do \n" +
                  "not see at a glance. Something that is far that you \n" +
                  "need to walk toward. To achieve it, you need to \n" +
                  "visualize it in your mind. BUT here's the thing.…." ,
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat3: {
            geometry: "text",
            text: "The company would not transform into a world-class \n" +
                  "company UNTIL YOU become world-class. You must \n" +
                  "transform first, and only then will the company \n" +
                  "follow. For a company to be world-class, YOU must \n" +
                  "be world-class. To be world-class, you must know \n" +
                  "yourself. Your thoughts. Your beliefs. Your skills. \n" +
                  "Your talent. You must identify that. Only then will \n" +
                  "you be able to be, get to where we vision to be, \n" +
                  "become a WORLD CLASS employee, or in any subject \n" +
                  "matter.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat4: {
            geometry: "text",
            text: "What is our MISSION? Our MISSION at SAINS is to provide \n" +
                  "premium Digital Solutions and Services through engaging \n" +
                  "and collaborating with our stakeholders within Sarawak and \n" +
                  "beyond.  By understanding our mission, we will be able to \n" +
                  "achieve our vision. ",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat5: {
            geometry: "text",
            text: "How do we do it at SAINS? In SAINS, we hope you can \n" +
                  "build the right attitude and change your mindset as \n" +
                  "a new mindset brings new results. Mindset is a belief.\n" +
                  "A good mindset will shape your personality and will be \n" + 
                  "attracting good opportunities. We must change our mindset.\n" +
                  "Re-assess what we believe in, do not procrastinate, and \n" +
                  "open our minds to examine our beliefs from time to time. \n" +
                  "Remember, belief in YOURSELF. Keep on learning new \n" +
                  "things every day, build the right attitude, and change \n" +
                  "your mindset as a new mindset brings new results.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat6: {
            geometry: "text",
            text: "Last but not least, \"Don't AGE with SAINS, \n" +
                  "but Grow with SAINS.\"",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
    },
    cfo: {
        back: {
            geometry: "text",
            text: "CEO Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        next: {
            geometry: "text",
            text: "Section Head \n"+
            "\ \ \ \ \ \ \ Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        chat: {
            geometry: "text",
            text: "Hello and welcome to SAINS. Congratulation on being a part \n" +
                  "of the SAINS big family. I am Christopher Wong, the Chief \n" +
                  "Financial Officer of SAINS.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat2: {
            geometry: "text",
            text: "In my Chief Financial Office, we plan and manage the \n" +
                  "overall company's financial affairs. From developing \n" +
                  "budgets, communicating with the company's banking \n" +
                  "partner, advising on strategic matters related to \n" +
                  "finances, investment initiatives, and managing the \n" +
                  "overall governance of Finance & Tax plus Supply & \n" +
                  "Chain Distribution.  We also ensure that the company \n" +
                  "complies with all the regulations and statutory \n" + 
                  "requirements.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat3: {
            geometry: "text",
            text: "We are divided into two major areas, The first one is the \n" +
                  "Finance & Tax section which covers the Financial \n" +
                  "Management, Tax Management, and Subsidiaries Unit. And \n" +
                  "the second section is the Supply Chain & Distribution \n" +
                  "Management, where we have another two smaller units: \n" +
                  "the Supply Chain Management and Distribution & Logistic Unit.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat4: {
            geometry: "text",
            text: "In this second section, we drive and develop the sourcing \n" +
                  "and distribution strategies for the company. We would \n" +
                  "develop an efficient Logistic & Distribution Strategies \n" +
                  "Process for the company to make sure that our warehouse \n" +
                  "inventory, storage space, material handling, packaging, \n" +
                  "and transportation schedule is on the optimum flow, Making \n" +
                  "sure that we can deliver what we promise our customers.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat5: {
            geometry: "text",
            text: "That would be a glance at our day-to-day operation here in \n" +
                  "my team. I hope that you will grow along with SAINS Never \n" +
                  "stop learning, and welcome aboard. ",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
    },
    coo: {
        back: {
            geometry: "text",
            text: "Section Head \n"+
            "\ \ \ \ \ \ \ Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        next: {
            geometry: "text",
            text: "CTD Director \n"+
            "\ \ \ \ \ \ \ Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        chat: {
            geometry: "text",
            text: "Greetings and a warm welcome onboard. I'm Busiai Bin Seman, \n" +
                  "the Chief Operation Officer of SAINS. SAINS is the best \n" +
                  "choice and the best company to grow your career.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat2: {
            geometry: "text",
            text: "Over here, the responsibilities and functions of the \n" +
                  "COO Office are to work alongside the CEO to execute the \n" +
                  "company vision, put procedures and resources in place to \n" +
                  "promote scaling, optimize weak areas in the organization \n" +
                  "and coordinate our strategic plans with divisional directors \n" +
                  "and executives. In COO Office, we have two (2) different \n" +
                  "sections that handle scopes: the Project Execution & \n" +
                  "Corporate Management Section.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat3: {
            geometry: "text",
            text: "Our Project Execution & Management Section will be \n" +
                  "operating a one-stop project management office to manage \n" +
                  "projects efficiently.  Meanwhile, the Corporate Management \n" +
                  "Team will be the one who deals mostly with standards, \n" +
                  "compliance, and office administration and operation.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat4: {
            geometry: "text",
            text: "Therefore, whether you are a new joiner in SAINS, you \n" +
                  "must be able to identify your strength. Be daring to take \n" +
                  "the challenge. Over time, you will keep on learning from \n" +
                  "people who have the knowledge you want to have. And with \n" +
                  "that, you will naturally upgrade yourself. ",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat5: {
            geometry: "text",
            text: "Once again, welcome to SAINS. It's a pleasure to have \n" +
                  "you on board.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
    },
    cto: {
        back: {
            geometry: "text",
            text: "CEO Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        next: {
            geometry: "text",
            text: "Section Head \n"+
                  "\ \ \ \ \ \ \ Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        chat: {
            geometry: "text",
            text: "Hello and welcome onboard to SAINS. I am Grant Than, \n" +
                  "the Chief Technology Officer for SAINS. As a CTO, \n" +
                  "most of my areas of expertise are in the technical \n" +
                  "areas. It varies from \n" +
                  "<1> Technology Adoption & Retirement Process \n" +
                  "<2> Solution Architecture Endorsement and\n        Implementation Process \n" +
                  "<3> Technology  Direction and Standards",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat2: {
            geometry: "text",
            text: "Here in SAINS, the CTO Office, we have two main functions...",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat3: {
            geometry: "text",
            text: "The first is to evaluate and implement new technological \n" +
                  "resources that would help execute the company's strategies. \n" +
                  "To provide technical support in a consultancy manner for \n" +
                  "internal and external customers. And to collaborate with \n" +
                  "internal technical teams on strategic technologies adoption \n" +
                  "and implementation." ,
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat4: {
            geometry: "text",
            text: "Secondly, to design, develop, implement, and maintain \n" +
                  "health care systems for private and public hospitals. \n" +
                  "The health care system includes Hospital Information \n" +
                  "systems, Laboratory Information systems, Operation \n" +
                  "Theater Management systems, and diagnosis group-related \n" +
                  "systems." ,
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat5: {
            geometry: "text",
            text: "We are constantly embracing new technologies to help the \n" +
                  "company in moving forward. We need to learn one new thing \n" +
                  "every day to bring the company forward. In support of the \n" +
                  "company's mission in providing premium digital solutions \n" +
                  "and services.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
    },
    directorcrm: {
        ambd: {
            geometry: "cylinder",
            height: 1,
            radius: 4,
            material: null
        },
        smi: {
            geometry: "cylinder",
            height: 1,
            radius: 4,
            material: null
        },
        slm: {
            geometry: "cylinder",
            height: 1,
            radius: 4,
            material: null
        },
        ccs: {
            geometry: "cylinder",
            height: 1,
            radius: 4,
            material: null
        },
        back: {
            geometry: "text",
            text: "CFO Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0x000000,
            })
        },
        next: {
            geometry: "text",
            text: "First Day",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0x000000,
            })
        },
    },
    directorctd: {
        back: {
            geometry: "text",
            text: "COO Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        next: {
            geometry: "text",
            text: "CFO Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        chat: {
            geometry: "text",
            text: "Welcome to SAINS. I am Samuel Lee Siaw Kang, and I am \n" +
                  "currently the Director of the Corporate Transformation \n" +
                  "Division, better known as CTD. In our lives, we need \n" +
                  "inspiration to reach our life goals. With the right \n" +
                  "inspiration, we won't give up easily.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat2: {
            geometry: "text",
            text: "In SAINS, we practice 5 main Core values as a fundamental \n" +
                  "belief to achieve our goals, be it in an organization or \n" +
                  "as an individual.  In short, we call it INSPIRE. It serves \n" +
                  "as a compass to bring us forward. \n" +
                  "Check out the tokens to find out about the core values.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat3: {
            geometry: "text",
            text: "To all of you, you are chosen and our trusted future leaders. \n" +
                  "You are very important to the company, entrusted, and be \n" +
                  "inspired. Never stop acquiring inspiration from one another, \n" +
                  "and inspiring the rest.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat4: {
            geometry: "text",
            text: "Be the change that you want to see in the organization. \n" +
                  "Never stop trying. Lastly, it's my pleasure to have you \n" +
                  "joining us in SAINS.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        integrity: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        passion: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        innovation: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        respect: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        excellence: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
    },
    firstday: {
        back: {
            geometry: "text",
            text: "CRM Director \n"+
            "\ \ \ \ \ \ \ Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0x000000,
            })
        },
    },
    sectionhead: {
        back: {
            geometry: "text",
            text: "CTO Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        next: {
            geometry: "text",
            text: "COO Office",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
                color: 0xffffff,
            })
        },
        chat: {
            geometry: "text",
            text: "A very warm welcome to SAINS. I am Jong Ann Chee, the \n" +
                  "Section Head for the Corporate Management Section under \n" +
                  "Chief Operation Officer's Office. In Corporate Management, \n" +
                  "we divide ourselves into two (2) smaller units, which\n" +
                  "is the Standard & Compliance Unit and the Corporate \n" +
                  "Administration Unit.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat2: {
            geometry: "text",
            text: "In the first unit, we identify potential threats and \n" +
                  "develop and implement a framework of prevention and \n" +
                  "recovery of business operations. We also deal with Risk \n" +
                  "Management, managing enterprise risk assessment, and \n" +
                  "compliance. The other business unit is where we manage \n" +
                  "the generic office operation. This generic one varies \n" +
                  "from office administration, fixed asset management, \n" +
                  "corporate license management, corporate information \n" +
                  "planning and management, and IP management. ",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat3: {
            geometry: "text",
            text: "Here at SAINS, we are ISO certified and adopt models in our \n" + 
                  "daily operations. Find out about our certifications and \n" + 
                  "models in the tokens. Please also check our postings \n" +
                  "available through the SAINS portal for more details on all the \n" +
                  "matters mentioned on QMS, ISMS, CMMI, and BCP. We have \n" +
                  "all our standards published here for you to \n" +
                  "understand it better.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        chat5: {
            geometry: "text",
            text: "I'm very happy to have you here with us, and this \n" +
                  "will be a great beginning of your career life at SAINS.",
            params: {
                font: fontLoader.parse(helvetiker),
                size: 1,
                height: 0.1
            },
            material:  new MeshBasicMaterial({
            color: 0xffffff,
            })
        },
        iso9001: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        iso27001: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        kpi: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
        bcp: {
            geometry: "cylinder",
            height: 1,
            radius: 5,
            material: null
        },
    },
}

export let entranceProps = {
    entranceroom:{
        scale: {
            x: 5,
            y: 5,
            z: 5
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -10,
            y: -8,
            z: 30
        },
        // place: false
    },
    entranceceo:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.2 * Math.PI,
            z: 0
        },
        position: {
            x: 32,
            y: -7.7,
            z: 1.5
        }
    },
    entrancecoo:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -15,
            y: -7.7,
            z: -8
        }
    },
    entrancecfo:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -10,
            y: -7.7,
            z: -10
        }
    },
    entrancecto:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.2 * Math.PI,
            z: 0
        },
        position: {
            x: 28,
            y: -7.7,
            z: -3
        }
    },
    entranceshcm:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.1 * Math.PI,
            z: 0
        },
        position: {
            x: 2,
            y: -7.7,
            z: -10
        }
    },
    entrancectdd:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -4,
            y: -7.7,
            z: -10
        }
    },
    entrancecrmd:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.1 * Math.PI,
            z: 0
        },
        position: {
            x: 23,
            y: -7.7,
            z: -6
        }
    },
    next_text: {
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: 25,
            y: -4,
            z: -28
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/receptionist",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    arrow:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: Math.PI,
            z: 0,
        },
        position: {
            x: 35,
            y: -7.8,
            z: -35
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/receptionist",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0,
                y: 0,
                z: 0.09
            },
            min: {
                x: 35,
                y: -7.8,
                z: -35
            },
            max: {
                x: 35,
                y: -7.8,
                z: -34
            },
        }
    }, 
}

export const receptionistProps = {
    receptionistroom:{
        scale: {
            x: 2,
            y: 2,
            z: 2
        },
        rotate: {
            x: 0,
            y: -0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -3,
            y: 1,
            z: -40
        }
    },
    back_text: {
        scale: {
            x: 0.4,
            y: 0.4,
            z: 0.4
        },
        rotate: {
            x: 0,
            y: 0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -3,
            y: 6,
            z: -9.5
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/entrance",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    next_text: {
        scale: {
            x: 0.3,
            y: 0.3,
            z: 0.3
        },
        rotate: {
            x: 0,
            y: -0.5 * Math.PI,
            z: 0,
        },
        position: {
            x: 39.8,
            y: 5.5,
            z: -32.7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/Ceo",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    lady1:{
        scale: {
            x: 2.5,
            y: 2.5,
            z: 2.5
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0,
        },
        position: {
            x: 2,
            y: 1.3,
            z: -6
        },
        animation: {
            animation: "follow",
            value: {
                position: {
                    x: 2,
                    y: 1.3,
                    z: -6
                },
                rotation:{
                    x: 0,
                    y: 0,
                    z: 0,
                } ,
                scale:{
                    x: 9,
                    y: 9,
                    z: 9
                },
            }
        },
        // place: false,
    },
    lady2:{
        scale: {
            x: 2.5,
            y: 2.5,
            z: 2.5
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0,
        },
        position: {
            x: 2,
            y: 1.3,
            z: -6
        },
        animation: {
            animation: "follow",
            value: {
                position: {
                    x: 2,
                    y: 1.3,
                    z: -6
                },
                rotation:{
                    x: 0,
                    y: 0,
                    z: 0,
                } ,
                scale:{
                    x: 9,
                    y: 9,
                    z: 9
                },
            }
        },
        place: false,
    },
    lady3:{
        scale: {
            x: 2.5,
            y: 2.5,
            z: 2.5
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0,
        },
        position: {
            x: 2,
            y: 1.3,
            z: -6
        },
        animation: {
            animation: "follow",
            value: {
                position: {
                    x: 2,
                    y: 1.3,
                    z: -6
                },
                rotation:{
                    x: 0,
                    y: 0,
                    z: 0,
                } ,
                scale:{
                    x: 9,
                    y: 9,
                    z: 9
                },
            }
        },
        place: false
    },
    arrow:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0.6 * Math.PI,
            z: 0,
        },
        position: {
            x: 20,
            y: 1.2,
            z: -24
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/ceo"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.04,
                y: 0,
                z: 0
            },
            min: {
                x: 20,
                y: 1.2,
                z: -24
            },
            max: {
                x: 20.5,
                y: 1.2,
                z: -24
            },
        }
    },
    arrow1:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.5 * Math.PI,
            z: 0
        },
        position: {
            x: -2.5,
            y: 1.2,
            z: -12
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/entrance"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.04,
                y: 0,
                z: 0
            },
            min: {
                x: -3,
                y: 1.2,
                z: -4
            },
            max: {
                x: -2.5,
                y: 1.2,
                z: -4
            },
        }
    },
}

export const ceoProps = {
    ceoroom:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -185,
            y: -9,
            z: 50
        }
    },
    chat_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
    },
    chat2_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat3_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat4_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat5_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat6_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    next_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: -49.3
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/cto",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    back_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: 14.9
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/receptionist",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    arrow:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.50 * Math.PI,
            z: 0
        },
        position: {
            x: -20,
            y: -4.8,
            z: -47
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/cto"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0
            },
            min: {
                x: -20,
                y: -6.8,
                z: -47
            },
            max: {
                x: -19,
                y: -6.8,
                z: -47
            },
        }
    },
    arrow1:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.3* Math.PI,
            z: 0
        },
        position: {
            x: -26,
            y: -4.8,
            z: 7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/receptionist"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0.1
            },
            min: {
                x: -28,
                y: -4.8,
                z: 8
            },
            max: {
                x: -27,
                y: -4.8,
                z: 9
            },
        }
    },
    boss:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
    },
    boss2:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    boss3:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    boss4:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    boss5:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
}

export const cfoProps = {
    cforoom:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -185,
            y: -9,
            z: 50
        }
    },
    next_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: -48.7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/directorcrm",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    back_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: 14.9
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/directorctd",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    chat_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
    },
    chat2_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat3_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat4_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat5_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    arrow:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.50 * Math.PI,
            z: 0
        },
        position: {
            x: -20,
            y: -4.8,
            z: -47
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/directorcrm"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0
            },
            min: {
                x: -20,
                y: -6.8,
                z: -47
            },
            max: {
                x: -19,
                y: -6.8,
                z: -47
            },
        }
    },
    arrow1:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.3* Math.PI,
            z: 0
        },
        position: {
            x: -26,
            y: -4.8,
            z: 7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/directorctd"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0.1
            },
            min: {
                x: -28,
                y: -4.8,
                z: 8
            },
            max: {
                x: -27,
                y: -4.8,
                z: 9
            },
        }
    },
    cfo1:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
    },
    cfo2:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    cfo3:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
}

export const cooProps = {
    cooroom:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -185,
            y: -9,
            z: 50
        }
    },
    next_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: -48.7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/directorctd",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    back_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: 15.6
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/sectionhead",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    chat_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -6.2,
            y: 13,
            z: -57
        },
    },
    chat2_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat3_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat4_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat5_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    arrow:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.50 * Math.PI,
            z: 0
        },
        position: {
            x: -20,
            y: -4.8,
            z: -47
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/directorctd"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0
            },
            min: {
                x: -20,
                y: -6.8,
                z: -47
            },
            max: {
                x: -19,
                y: -6.8,
                z: -47
            },
        }
    },
    arrow1:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.3* Math.PI,
            z: 0
        },
        position: {
            x: -26,
            y: -4.8,
            z: 7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/sectionhead"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0.1
            },
            min: {
                x: -28,
                y: -4.8,
                z: 8
            },
            max: {
                x: -27,
                y: -4.8,
                z: 9
            },
        }
    },
    coo1:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
    },
    coo2:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    coo3:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    coo4:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
}

export const ctoProps = {
    ctoroom:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -185,
            y: -9,
            z: 50
        }
    },
    next_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: -48.7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/sectionhead",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    back_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: 15.2
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/ceo",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    chat_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
    },
    chat2_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat3_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat4_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat5_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    arrow:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.50 * Math.PI,
            z: 0
        },
        position: {
            x: -20,
            y: -4.8,
            z: -47
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/sectionhead"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0
            },
            min: {
                x: -20,
                y: -6.8,
                z: -47
            },
            max: {
                x: -19,
                y: -6.8,
                z: -47
            },
        }
    },
    arrow1:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.3* Math.PI,
            z: 0
        },
        position: {
            x: -26,
            y: -4.8,
            z: 7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/ceo"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0.1
            },
            min: {
                x: -28,
                y: -4.8,
                z: 8
            },
            max: {
                x: -27,
                y: -4.8,
                z: 9
            },
        }
    },
    cto1:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
    }
}

export const directorcrmProps = {
    directorcrmroom:{
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -3,
            y: 2.5,
            z: 1
        }
    },
    next_text: {
        scale: {
            x: 0.1,
            y: 0.1,
            z: 0.1
        },
        rotate: {
            x: 0,
            y: -0.5*Math.PI,
            z: 0
        },
        position: {
            x: 7.8,
            y: 4.4,
            z: -4.7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/firstd",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    back_text: {
        scale: {
            x: 0.1,
            y: 0.1,
            z: 0.1
        },
        rotate: {
            x: 0,
            y: -0.5*Math.PI,
            z: 0
        },
        position: {
            x: 7.8,
            y: 4.4,
            z: -10.2
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/cfo",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    arrow:{
        scale: {
            x: 4,
            y: 4,
            z: 4
        },
        rotate: {
            x: 0,
            y: 0.75* Math.PI,
            z: 0,
        },
        position: {
            x: 7.1,
            y: 3.1,
            z: -3.1
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/firstd"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.01,
                y: 0,
                z: 0.01
            },
            min: {
                x: 7,
                y: 3.1,
                z: -3.1
            },
            max: {
                x: 7.1,
                y: 3.1,
                z: -3
            },
        }
    },
    arrow1:{
        scale: {
            x: 4,
            y: 4,
            z: 4
        },
        rotate: {
            x: 0,
            y: 0.75* Math.PI,
            z: 0,
        },
        position: {
            x: 7.1,
            y: 3.1,
            z: -8.1
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/cfo"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.01,
                y: 0,
                z: 0.01
            },
            min: {
                x: 7,
                y: 3.1,
                z: -8.1
            },
            max: {
                x: 7.1,
                y: 3.1,
                z: -8
            },
        }
    },
    directorcrm1:{
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0,
        },
        position: {
            x: 3,
            y: 2.7,
            z: -2.5
        },
        animation: {
            animation: "follow",  
        },
    },
    directorcrm2:{
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0,
        },
        position: {
            x: 3,
            y: 2.7,
            z: -2.5
        },
        animation: {
            animation: "follow",  
        },
        place: false
    },
    directorcrm3:{
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0,
        },
        position: {
            x: 3,
            y: 2.7,
            z: -2.5
        },
        animation: {
            animation: "follow",  
        },
        place: false
    },
    directorcrm4:{
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0,
        },
        position: {
            x: 3,
            y: 2.7,
            z: -2.5
        },
        animation: {
            animation: "follow",  
        },
        place: false
    },
    directorcrm5:{
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0,
        },
        position: {
            x: 3,
            y: 2.7,
            z: -2.5
        },
        animation: {
            animation: "follow",  
        },
        place: false
    },
    ambd_cylinder: {
        scale: {
            x: 0.06,
            y: 0.06,
            z: 0.06
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 2,
            y: 4.5,
            z: -5
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "Serves as the lead point of contact for all customer account management matters, business, and event management, building and maintaining strong, long-lasting customer relationships.",
                title: "Account Management & Business Development (AMBD)"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    smi_cylinder: {
        scale: {
            x: 0.06,
            y: 0.06,
            z: 0.06
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 2,
            y: 4.5,
            z: -7
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "Manages strategic accounts/tasks related to service level and ensures commitments are met.Manage and provide customer intelligence information for CRM and the company for further decision making. Provide customer-centric functions such as corporate publications, corporate branding, and user interface.",
                title: "Service Management & Intelligence (SMI)"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    slm_cylinder: {
        scale: {
            x: 0.06,
            y: 0.06,
            z: 0.06
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 2,
            y: 4.5,
            z: -9
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "Serves as the point of contact for customers on Service Level Agreement (SLA), ensuring timely review and negotiating contract scope and pricing. Monitor ISPs perform the metrics that our organization is obligated to meet and ensure execution of SLAs commitment and that all SLAs are billed timely.",
                title: "Service Level Management (SLM) "
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    ccs_cylinder: {
        scale: {
            x: 0.06,
            y: 0.06,
            z: 0.06
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 2,
            y: 4.5,
            z: -11
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "The largest team in CRM serves as the central contact point channel for customer interaction via various channel communication modes. Assist with customer requests or issue escalations as needed; other functions shall include ICT Helpdesk, call-out outsourcing services, and the latest command center.",
                title: "Contact Centre Section"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
}

export const directorctdProps = {
    directorctdroom:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -185,
            y: -9,
            z: 50
        }
    },
    next_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: -49.3
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/cfo",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    back_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: 15.2
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/coo",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    chat_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
    },
    chat2_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat3_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat4_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    arrow:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.50 * Math.PI,
            z: 0
        },
        position: {
            x: -20,
            y: -4.8,
            z: -47
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/cfo"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0
            },
            min: {
                x: -20,
                y: -6.8,
                z: -47
            },
            max: {
                x: -19,
                y: -6.8,
                z: -47
            },
        }
    },
    arrow1:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.3* Math.PI,
            z: 0
        },
        position: {
            x: -26,
            y: -4.8,
            z: 7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/coo"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0.1
            },
            min: {
                x: -28,
                y: -4.8,
                z: 8
            },
            max: {
                x: -27,
                y: -4.8,
                z: 9
            },
        }
    },
    directorctd1:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
    },
    directorctd2:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    directorctd3:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    integrity_cylinder: {
        scale: {
            x: 0.4,
            y: 0.4,
            z: 0.4
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: -10,
            y: 2,
            z: -50
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "The first core value is Integrity. What is Integrity? Integrity is upholding a high standard of governance. Doing the right thing, making decisions with a clear conscience, and ensuring no conflict of interest.",
                title: "Integrity"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    passion_cylinder: {
        scale: {
            x: 0.4,
            y: 0.4,
            z: 0.4
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: -5,
            y: 2,
            z: -50
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "The second core value in SAINS is Passion. Passion towards building our work and brand here at SAINS. Protecting it from being tarnished and promoting it to everyone with our good services.",
                title: "Passion"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    innovation_cylinder: {
        scale: {
            x: 0.4,
            y: 0.4,
            z: 0.4
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 0,
            y: 2,
            z: -50
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "The third core value that we hold onto is Innovation. In SAINS, we have a committee called SIIC (SAINS Innovative Ideas Committee) that drives our company to always be up to date with the latest technology but not only limited to products and services. It could also be in the improvement form of process, employee experience, and many other areas. With innovation, SAINS will constantly improve to be better from time to time.",
                title: "Innovation"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    respect_cylinder: {
        scale: {
            x: 0.4,
            y: 0.4,
            z: 0.4
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 5,
            y: 2,
            z: -50
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "The fourth core value in SAINS is Respect. At our company, we practice a high level of respect towards each other regardless of your position. We give everyone the same amount of opportunity to express their ideas.",
                title: "Respect"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    excellence_cylinder: {
        scale: {
            x: 0.4,
            y: 0.4,
            z: 0.4
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 10,
            y: 2,
            z: -50
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "The fifth core value that we emphasize in SAINS is Excellence. As an employee of SAINS, we always aim for the highest standard. We give the best. We do our best. We produce the best. We strive for excellence and the best outcome in everything that we do.",
                title: "Excellence"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
}

export const firstdayProps = {
    firstdayroom:{
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -10,
            y: 2.5,
            z: 5.5
        }
    },
    back_text: {
        scale: {
            x: 0.1,
            y: 0.1,
            z: 0.1
        },
        rotate: {
            x: 0,
            y: -0.5*Math.PI,
            z: 0
        },
        position: {
            x: 1.7,
            y: 5.25,
            z: -5.94
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/directorcrm",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    arrow1:{
        scale: {
            x: 3,
            y: 3,
            z: 3
        },
        rotate: {
            x: 0,
            y: 0.5* Math.PI,
            z: 0
        },
        position: {
            x: 1.4,
            y: 2.7,
            z: -5
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/directorcrm"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.01,
                y: 0,
                z: 0
            },
            min: {
                x: 1.3,
                y: 2.7,
                z: -5
            },
            max: {
                x: 1.4,
                y: 2.7,
                z: -5
            },
        }
    },
    firstday1:{
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.24* Math.PI,
            z: 0
        },
        position: {
            x: -2,
            y: 2.7,
            z: -1
        },
        animation: {
            animation: "follow",  
        },
    },
    firstday2:{
        scale: {
            x: 1,
            y: 1,
            z: 1
        },
        rotate: {
            x: 0,
            y: 0.24* Math.PI,
            z: 0
        },
        position: {
            x: -2,
            y: 2.7,
            z: -1
        },
        animation: {
            animation: "follow",  
        },
        place: false
    },
}

export const sectionheadProps = {
    sectionheadroom:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -185,
            y: -9,
            z: 50
        }
    },
    next_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: -49.3
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/coo",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    back_text: {
        scale: {
            x: 0.7,
            y: 0.7,
            z: 0.7
        },
        rotate: {
            x: 0,
            y: 0.5*Math.PI,
            z: 0
        },
        position: {
            x: -34.5,
            y: 8,
            z: 15.2
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/cto",
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
    },
    chat_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
    },
    chat2_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat3_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat4_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    chat5_text: {
        scale: {
            x: 0.55,
            y: 0.55,
            z: 0.55
        },
        rotate: {
            x: 0,
            y: 0,
            z: 0
        },
        position: {
            x: -5.8,
            y: 13,
            z: -57
        },
        place: false
    },
    arrow:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.50 * Math.PI,
            z: 0
        },
        position: {
            x: -20,
            y: -4.8,
            z: -47
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/coo"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0
            },
            min: {
                x: -20,
                y: -6.8,
                z: -47
            },
            max: {
                x: -19,
                y: -6.8,
                z: -47
            },
        }
    },
    arrow1:{
        scale: {
            x: 30,
            y: 30,
            z: 30
        },
        rotate: {
            x: 0,
            y: -0.3* Math.PI,
            z: 0
        },
        position: {
            x: -26,
            y: -4.8,
            z: 7
        },
        interactions: [
            {
                action: "click",
                handler: () => window.location = "/cto"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ],
        animation: {
            animation: "shake",
            value: {
                x: 0.1,
                y: 0,
                z: 0.1
            },
            min: {
                x: -28,
                y: -4.8,
                z: 8
            },
            max: {
                x: -27,
                y: -4.8,
                z: 9
            },
        }
    },
    sectionh1:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
    },
    sectionh2:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    sectionh3:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    sectionh4:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    sectionh5:{
        scale: {
            x: 10,
            y: 10,
            z: 10
        },
        rotate: {
            x: 0,
            y: -0.15* Math.PI,
            z: 0,
        },
        position: {
            x: 16,
            y: -7,
            z: -50
        },
        place: false
    },
    iso9001_cylinder: {
        scale: {
            x: 0.4,
            y: 0.4,
            z: 0.4
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: -5,
            y: 2,
            z: -50
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "ISO 9001:2015, which is known as QMS, is based on the idea of customer satisfaction and continual improvement. It is a set of processes defined and implemented to enable an organization to satisfy the needs of its customers. Therefore, these standards are implemented to guide us and continually satisfy our customers. Like ISMS or CIA, our ISO 9001 or QMS covers our Data Centre.",
                title: "ISO9001:2015"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    iso27001_cylinder: {
        scale: {
            x: 0.4,
            y: 0.4,
            z: 0.4
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 0,
            y: 2,
            z: -50
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "For ISO 27001:2013, which in short we refer to as ISMS or easier to remember as CIA (Confidentiality, Integrity, Availability), is a standard of preservation of information. Currently, our certification scope covers our Data Centre. Why do we need a proper standard rule to guide our data? Every data we have has monetary value, depending on the accuracy and the potential of use that could lead to many business opportunities and to make present or future decisions.",
                title: "ISO27001:2013"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    kpi_cylinder: {
        scale: {
            x: 0.4,
            y: 0.4,
            z: 0.4
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 5,
            y: 2,
            z: -50
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "In SAINS, we also apply the Capability Maturity Model Integration for Development or CMMI to help drive our business performance by building and benchmarking key capabilities. There are five maturity levels in CMMI, starting from ML 1 as the lowest and ML 5 as the highest level of capability or performing standard. And SAINS is currently at ML 3, which is in the mid-range DEFINED level.",
                title: "Capability Maturity Model Integration"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
    bcp_cylinder: {
        scale: {
            x: 0.4,
            y: 0.4,
            z: 0.4
        },
        rotate: {
            x: Math.PI * 0.5,
            y: Math.PI * 0.5,
            z: 0
        },
        position: {
            x: 10,
            y: 2,
            z: -50
        },
        animation: {
            animation: "rotate",
            value: {
                x: Math.PI * 0.015,
                y: Math.PI * 0.015,
                z: 0
            }
        },
        interactions: [
            {
                action: "click",
                handler: "modal",
                content: "Here at SAINS, we also look very deeply into our Business Continuity Planning (BCP) A process where we identify threats develops and implements a framework of prevention and recovery of business operations. BC Plan has documented procedures that guide organizations to respond, recover, resume and restore to a predefined level of operations following disruptions. It's important to make sure that we can continue our business after a drastic disruption.",
                title: "Business Continuity Planning"
            },
            {
                action: "mouseover",
                handler: () => {document.body.style.cursor = "pointer"}
            },
            {
                action: "mouseout",
                handler: () => {document.body.style.cursor = "default"}
            }
        ]
    },
}