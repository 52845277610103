import up from "../assets/img/up.png"
import down from "../assets/img/down.png"
import left from "../assets/img/left.png"
import right from "../assets/img/right.png"
import w from "../assets/img/w.png"
import a from "../assets/img/a.png"
import s from "../assets/img/s.png"
import d from "../assets/img/d.png"
import click from "../assets/img/d.png"
import mleft from "../assets/img/mleft.png"
import mright from "../assets/img/mright.png"
import mfront from "../assets/img/mfront.png"
import mback from "../assets/img/mback.png"
import iosleft from "../assets/img/iosleft.png"
import iosright from "../assets/img/iosright.png"
import iosfront from "../assets/img/iosfront.png"
import iosback from "../assets/img/iosback.png"
import map from "../assets/img/map.png"
import { isIOS } from "react-device-detect"
import { Button } from "reactstrap"
import { Step, StepButton, StepLabel, Stepper } from "@mui/material"

export function iframeContent(url) {
    return (
        <div>
            <iframe src={url}/>
        </div>
    )
}

export function imgContent(resource) {
    return (
        <img src={resource} width="100%" />
    )
}

export function videoContent(resource) {
    return (
        <video src={resource} controls />
    )
}

const pcInstructions = (
    <>
        <h2>PC Controls</h2>
        <table className="instructions-table">
            <tr>
                <td>
                <img src={up} />
                or
                <img src={w} />
                </td>
                <td>Move forward</td>
            </tr>
            <tr>
                <td>
                <img src={down} />
                or
                <img src={s} />
                </td>
                <td>Move backward</td>
            </tr>
            <tr>
                <td>
                <img src={left} />
                or
                <img src={a} />
                </td>
                <td>Turn left</td>
            </tr>
            <tr>
                <td>
                <img src={right} />
                or
                <img src={d} />
                </td>
                <td>Turn right</td>
            </tr>
            <tr>
                <td>
                <img src={click} />
                </td>
                <td>Click to interact</td>
            </tr>
        </table>
    </>
)

const mobileInstructions = (
    <>
        <table className="ios-instructions-table">
            <tr>
                <td>
                    <img src={mfront} />
                </td>
                <td>
                    <img src={mback} />
                </td>
                <td>
                    <img src={mleft} />
                </td>
                <td>
                    <img src={mright} />
                </td>
                <td colSpan={2}>Click on items to interact</td>
            </tr>
            <tr>
                <td>Move forward</td>
                <td>Move backward</td>
                <td>Turn left</td>
                <td>Turn right</td>
            </tr>
        </table>
    </>
)

const mobileIosInstructions = (
    <>
        <table className="ios-instructions-table">
            <tr>
                <td>
                    <img src={iosfront} />
                </td>
                <td>
                    <img src={iosback} />
                </td>
                <td>
                    <img src={iosleft} />
                </td>
                <td>
                    <img src={iosright} />
                </td>
                <td colSpan={2}>Click on items to interact</td>
            </tr>
            <tr>
                <td>Move forward</td>
                <td>Move backward</td>
                <td>Turn left</td>
                <td>Turn right</td>
            </tr>
        </table>
    </>
)

export const instructions = () => {
    if (window.innerWidth < window.innerHeight) {
        if (isIOS) {
            return mobileIosInstructions
        }
        else {
            return mobileInstructions
        }
    } else {
        return pcInstructions
    }
}

export const siteMap = (currentStep) => {
    const steps = [
        {
            label: "Reception",
            url: "/receptionist"
        },
        {
            label: "CEO Office",
            url: "/ceo",
        },
        {
            label: "CTO Office",
            url: "/cto",
        },
        {
            label: "Corporate Management Office",
            url: "/sectionhead",
        },
        {
            label: "COO Office",
            url: "/coo",
        },
        {
            label: "CTD Director Office",
            url: "/directorctd",
        },
        {
            label: "CFO Office",
            url: "/cfo",
        },
        {
            label: "Contact Centre",
            url: "/directorcrm",
        },
        {
            label: "Conference Room",
            url: "/firstd"
        }
    ]
    const stepUrls = [
    ]
    return (
        <>
            <div>
                <Stepper activeStep={currentStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label.labell} onClick={() => window.location = label.url} onMouseOver={(e) => e.target.style.cursor = "pointer"}>
                            <StepButton>{label.label}</StepButton>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <div className="map-container">
                <img src={map} />
                <Button
                    onClick={() => window.location = "/receptionist"}
                    style={{
                        width: '311px',
                        height: '323px',
                        right: '17px',
                        bottom: '6px'
                    }}
                    title="Reception"
                />
                <Button
                    onClick={() => window.location = "/ceo"}
                    style={{
                        width: '220px',
                        height: '298px',
                        right: '108px',
                        top: '35px'
                    }}
                    title="CEO Office"
                />
                <Button
                    onClick={() => window.location = "/cto"}
                    style={{
                        width: '296px',
                        height: '220px',
                        right: '333px',
                        top: '35px'
                    }}
                    title="CTO Office"
                />
                <Button
                    onClick={() => window.location = "/sectionhead"}
                    style={{
                        width: '164px',
                        height: '220px',
                        left: '299px',
                        top: '35px'
                    }}
                    title="Corporate Management Office"
                />
                <Button
                    onClick={() => window.location = "/coo"}
                    style={{
                        width: '220px',
                        height: '272px',
                        left: '6px',
                        top: '35px'
                    }}
                    title="COO Office"
                />
                <Button
                    onClick={() => window.location = "/directorctd"}
                    style={{
                        width: '220px',
                        height: '164px',
                        left: '6px',
                        top: '312px'
                    }}
                    title="CTD Director Office"
                />
                <Button
                    onClick={() => window.location = "/cfo"}
                    style={{
                        width: '296px',
                        height: '220px',
                        left: '299px',
                        top: '259px'
                    }}
                    title="CFO Office"
                />
                <Button
                    onClick={() => window.location = "/directorcrm"}
                    style={{
                        width: '165px',
                        height: '272px',
                        left: '601px',
                        top: '259px'
                    }}
                    title="Contact Centre"
                />
                <Button
                    onClick={() => window.location = "/firstd"}
                    style={{
                        width: '290px',
                        height: '127px',
                        left: '6px',
                        bottom: '6px'
                    }}
                    title="Conference Room"
                />
            </div>
        </>
    )
}
