import React from "react";
import Entrance from "./location/Entrance"
import CEO from "./location/CEO";
import CFO from "./location/CFO";
import COO from "./location/COO";
import CTO from "./location/CTO";
import DirectorCRM from "./location/DirectorCRM";
import DirectorCTD from "./location/DirectorCTD";
import FirstDay from "./location/FirstDay";
import Receptionist from "./location/Receptionist";
import SectionHead from "./location/SectionHead";
import {
  BrowserRouter,
  Routes,
  Route,
  Redirect,
  Navigate
} from "react-router-dom";
import "./assets/css/nucleo-icons.css";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/entrance" element={<Entrance />} />
        <Route path="/ceo" element={<CEO />} />
        <Route path="/cfo" element={<CFO />} />
        <Route path="/coo" element={<COO />} />
        <Route path="/cto" element={<CTO />} />
        <Route path="/directorcrm" element={<DirectorCRM />} />
        <Route path="/directorctd" element={<DirectorCTD />} />
        <Route path="/firstd" element={<FirstDay />} />
        <Route path="/receptionist" element={<Receptionist />} />
        <Route path="/sectionhead" element={<SectionHead />} />
        <Route path="" element={<Navigate to="/entrance" />} />
        <Route path="/" element={<Navigate to="/entrance" />} />
      </Routes>
    </BrowserRouter>
  )
}

