import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { TextureLoader, RepeatWrapping, MeshBasicMaterial, DoubleSide, MeshPhongMaterial } from "three"

export function LoadGltf (SetModel, ThrowException, path, key) {
    const loader = new GLTFLoader()
    loader.load(path, function (gltf) {
        SetModel({
            type: "model",
            key: key,
            obj: gltf
        })
    }, undefined, function (error) {
        ThrowException(error)
    })
}

export function LoadTexture (SetTexture, ThrowException, path, key, RepeatX, RepeatY) {
    let loader = new TextureLoader()
    loader.load(path, function (texture) {
        texture.wrapS = RepeatWrapping
        texture.wrapT = RepeatWrapping
        texture.repeat.set(RepeatX, RepeatY)
        const material = new MeshBasicMaterial({
            map: texture,
            side: DoubleSide
        })
        SetTexture({
            type: "texture",
            key: key,
            obj: material
        })
    }, undefined, function (error) {
        ThrowException(error)
    })
}

export function LoadFont(SetFont, ThrowException, path, key) {
    let loader = new FontLoader();
    loader.parse(path, function(font) {
        SetFont({
            type: "font",
            key: key,
            obj: font
        })
    }, undefined, function (error) {
        ThrowException(error)
    })
}