import animate from '../helpers/animate';
import { instructions, siteMap } from '../helpers/modal';
import Place from '../helpers/place';
import { LoadData, LoadEnv, DrawObjects, LoadMaterials } from '../helpers/promises';
import { Component } from 'react';
import { Button, Col, Modal, Row } from 'reactstrap';
import { entranceProps } from '../objects';

export default class Entrance extends Component {
    componentDidMount() {
        // three js main codes
        let effects = []
        Promise.allSettled(LoadData()).then((data) => {
            Promise.allSettled(LoadMaterials("entrance")).then((materials) => {
                let textures = materials.filter(m => m.value.type === "texture")
                Promise.allSettled(DrawObjects("entrance", textures)).then((objects) => {
                    LoadEnv("entrance").then((env) => {
                        let {scene, camera, renderer, interaction, prevTime, movement, control} = env
                        materials.filter(m => m.value.type === "model")
                        .forEach((element) => {
                            let model = element.value.obj.scene.clone()
                            model.receiveShadow = true
                            model.castShadow = true
                            let props = entranceProps[element.value.key]
                            scene.add(Place(model, props, element.value.key))
                            if (props.hasOwnProperty("animation")) {
                                effects.push({
                                    animation: props.animation.animation,
                                    obj: model,
                                    value: props.animation.value,
                                    min: props.animation.min,
                                    max: props.animation.max,
                                    altered: null
                                })
                            }
                            if(props.hasOwnProperty("interactions")) {
                                interaction.add(model)
                                props.interactions.forEach((i) => {
                                    if (i.handler === "modal") {
                                        model.addEventListener(i.action, () => {
                                            this.setState(() => ({
                                                modal: true,
                                                modalContent: i.content,
                                                title: i.title
                                            }))
                                        })
                                    } else {
                                        model.addEventListener(i.action, i.handler)
                                    }
                                })
                            }
                        })
                        objects.forEach((element) => {
                            let propKey = element.value.key + "_" + element.value.type
                            let props = entranceProps[propKey]
                            scene.add(Place(element.value.model, props, element.value.key))
                            if (props.hasOwnProperty("animation")) {
                                effects.push({
                                    animation: props.animation.animation,
                                    obj: element.value.model,
                                    value: props.animation.value,
                                    min: props.animation.min,
                                    max: props.animation.max,
                                    altered: null
                                })
                            }
                            if(props.hasOwnProperty("interactions")) {
                                interaction.add(element.value.model)
                                props.interactions.forEach((i) => {
                                    if (i.handler === "modal") {
                                        element.value.model.addEventListener(i.action, () => {
                                            this.setState(() => ({
                                                modal: true,
                                                modalContent: i.content,
                                                title: i.title
                                            }))
                                        })
                                    } else {
                                        element.value.model.addEventListener(i.action, i.handler)
                                    }
                                })
                            }
                        })

                        animate(scene, camera, renderer, prevTime, movement, control, effects, interaction)
                        if (!localStorage.getItem("visited")) {
                            this.setState(() => ({
                                modal: true,
                                modalContent: instructions(),
                                title: "Controls"
                            }))
                            localStorage.setItem("visited", true)
                        }
                        document.getElementById("location-label").classList.remove("d-none")
                    })
                })
            })
        })
    }
    constructor (props) {
        super(props)
        this.state = {
            modal: false,
            modalContent: null,
            title: null
        }
        
    }
    render() {
        let {modal, modalContent, title} = this.state
        const setModal = (isOpen, content, title) => {
            this.setState(() => ({
                modal: isOpen,
                modalContent: content,
                title: title
            }))
        }
        return (
            <>
                <div className="fixed-top text-center font-bold d-none" id="location-label">
                    <h1>Entrance</h1>
                    <h4>Welcome</h4>
                </div>
                <div className="fixed-bottom">
                    <Row>
                        <Col className="px-4 py-2">
                            <Button
                                className="btn-icon btn-round mx-2"
                                color="primary"
                                type="button"
                                onClick={() => setModal(true, instructions(), "Controls")}
                            >
                                <i className="tim-icons icon-controller" />
                            </Button>
                            <Button
                                className="btn-icon btn-round mx-2"
                                color="primary"
                                type="button"
                                onClick={() => setModal(true, siteMap(-1), "Site Map")}
                            >
                                <i className="tim-icons icon-map-big" />
                            </Button>
                        </Col>
                        <Col className="text-right px-4 py-2">
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={modal} toggle={() => setModal(false, null, null)}>
                    <div className="modal-header justify-content-center">
                        <button className="close" onClick={() => setModal(false, null, null)}>
                            <i className="tim-icons icon-simple-remove" />
                        </button>
                        <h4 className="title title-up">{title}</h4>
                    </div>
                    <div className="modal-body mt-3">
                        {modalContent}
                    </div>
                </Modal>
            </>
        )
    }
}